import natura from "./img/natura_siberica.png"
import organic from "./img/organic.png"
import rostics from "./img/rostics.png"
import citylink from "./img/cityLink.png"

export const partnersMain = [
  {
    id: 31,
    brand: "vseinstrumenti",
    icon_url: "https://api.halvacard.ru/public-api/files/abe93c75-6f27-4c3f-83f6-9c36b9687ddc.jpeg",
    urlName: "vseinstrumenti",
  },
  {
    id: 32,
    brand: "sokolov",
    icon_url: "https://api.halvacard.ru/public-api/files/8588a516-0076-43e6-a1b7-60b614c515fc.png",
    urlName: "sokolov",
  },
  {
    id: 33,
    brand: "natura_siberica",
    icon_url: natura,
    urlName: "natura_siberica",
  },
  {
    id: 34,
    brand: "organic",
    icon_url: organic,
    urlName: "organic",
  },
  {
    id: 35,
    brand: "flowwow",
    icon_url: "https://api.halvacard.ru/public-api/files/0a8a9f9f-4e78-4ce9-9e22-ad9d3a747055.png",
    urlName: "flowwow",
  },
  {
    id: 36,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 37,
    brand: "lukoil",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
]

export const partnersRostics = [
  {
    id: 31,
    brand: "rostics",
    icon_url: rostics,
    urlName: "rostics",
  },
  {
    id: 32,
    brand: "sokolov",
    icon_url: "https://api.halvacard.ru/public-api/files/8588a516-0076-43e6-a1b7-60b614c515fc.png",
    urlName: "sokolov",
  },
  {
    id: 33,
    brand: "natura_siberica",
    icon_url: natura,
    urlName: "natura_siberica",
  },
  {
    id: 34,
    brand: "organic",
    icon_url: organic,
    urlName: "organic",
  },
  {
    id: 35,
    brand: "flowwow",
    icon_url: "https://api.halvacard.ru/public-api/files/0a8a9f9f-4e78-4ce9-9e22-ad9d3a747055.png",
    urlName: "flowwow",
  },
  {
    id: 36,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 37,
    brand: "lukoil",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
  {
    id: 38,
    brand: "vseinstrumenti",
    icon_url: "https://api.halvacard.ru/public-api/files/abe93c75-6f27-4c3f-83f6-9c36b9687ddc.jpeg",
    urlName: "vseinstrumenti",
  },
]

export const partnersZoon = [
  {
    id: 31,
    brand: "vseinstrumenti",
    icon_url: "https://api.halvacard.ru/public-api/files/abe93c75-6f27-4c3f-83f6-9c36b9687ddc.jpeg",
    urlName: "vseinstrumenti",
  },
  {
    id: 32,
    brand: "ylibka_radugi",
    icon_url: "https://api.halvacard.ru/public-api/files/3c42ff64-da18-4ddc-8923-5e6772112524.jpeg",
    urlName: "ylibka_radugi",
  },
  {
    id: 33,
    brand: "ozon-ru",
    icon_url: "https://api.halvacard.ru/public-api/files/24983b4b-9d5e-40c4-8dd6-6349d436eec4.jpeg",
    urlName: "ozon-ru",
  },
  {
    id: 34,
    brand: "yandex-market",
    icon_url: "https://api.halvacard.ru/public-api/files/6287d5ab-6607-49a6-9b94-edce6c0e9b73.jpeg",
    urlName: "yandex-market",
  },
  {
    id: 35,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 36,
    brand: "lukoil",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
]

export const lenta = [
  {
    id: 31,
    brand: "lenta",
    icon_url: "https://api.halvacard.ru/public-api/files/88dcbbdf-947d-4741-9597-3f69ab9fe4a0.jpeg",
    urlName: "lenta",
  },
  {
    id: 32,
    brand: "lenta_online",
    icon_url: "https://api.halvacard.ru/public-api/files/57290b6e-14c7-49dc-9178-bc0f24e1b1c9.jpeg",
    urlName: "lenta_online",
  },
  {
    id: 33,
    brand: "sokolov",
    icon_url: "https://api.halvacard.ru/public-api/files/8588a516-0076-43e6-a1b7-60b614c515fc.png",
    urlName: "sokolov",
  },
  {
    id: 34,
    brand: "natura_siberica",
    icon_url: natura,
    urlName: "natura_siberica",
  },
  {
    id: 35,
    brand: "organic",
    icon_url: organic,
    urlName: "organic",
  },
  {
    id: 36,
    brand: "flowwow",
    icon_url: "https://api.halvacard.ru/public-api/files/0a8a9f9f-4e78-4ce9-9e22-ad9d3a747055.png",
    urlName: "flowwow",
  },
  {
    id: 37,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 38,
    brand: "lukoil",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
    urlName: "lukoil",
  },
]

export const autoPromo = [
  {
    id: 31,
    brand: "rolf",
    icon_url: "https://api.halvacard.ru/public-api/files/46db29c6-e795-480a-b173-4a138155eeb7.PNG",
    urlName: "rolf",
  },
  {
    id: 32,
    brand: "kari",
    icon_url: "https://api.halvacard.ru/public-api/files/357785b8-f328-4371-8ac0-2250e90ff132.jpeg",
    urlName: "kari",
  },
  {
    id: 33,
    brand: "kari KIDS",
    icon_url: "https://api.halvacard.ru/public-api/files/9f577c4c-ed16-4ba3-83d6-53d9c4d7d5a1.png",
    urlName: "kari-kids-16935838",
  },
  {
    id: 34,
    brand: "askona",
    icon_url: "https://api.halvacard.ru/public-api/files/38c29e64-8e97-44c8-9c6b-63bedb4cdd6b.png",
    urlName: "askona",
  },
  {
    id: 35,
    brand: "ostin",
    icon_url: "https://api.halvacard.ru/public-api/files/f2a50240-5d9a-4839-ab37-9058506e664a.png",
    urlName: "ostin",
  },
]

export const blackFriday = [
  {
    id: 31,
    brand: "yandex-market",
    icon_url: "https://api.halvacard.ru/public-api/files/6287d5ab-6607-49a6-9b94-edce6c0e9b73.jpeg",
    urlName: "yandex-market",
  },
  {
    id: 32,
    brand: "magnit-kosmetik",
    icon_url: "https://api.halvacard.ru/public-api/files/fdf3ac12-016c-4efd-ac5f-606d811ac27b.jpeg",
    urlName: "magnit-kosmetik",
  },
  {
    id: 33,
    brand: "hoff",
    icon_url: "https://api.halvacard.ru/public-api/files/59aebcf2-bd41-413c-8f36-c759f2d481a8.png",
    urlName: "hoff",
  },
  {
    id: 34,
    brand: "obi",
    icon_url: "https://api.halvacard.ru/public-api/files/85539422-b9d3-49ab-b346-20147d71d9ba.png",
    urlName: "obi",
  },
  {
    id: 35,
    brand: "cityLink",
    icon_url: citylink,
    urlName: "cityLink",
  },
]
