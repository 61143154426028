import React, { ReactElement, useEffect, useState } from "react"

import SwiperCore from "swiper"
import type { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import LazyLoad from "react-lazyload"
import Container from "@ecom/ui/components/Container"
import Box from "@material-ui/core/Box"

import clsx from "clsx"
import fetchShops from "./helpers/fetchShops"
import * as styles from "./partners.module.scss"
import { partnersMain } from "./helpers"

type Shop = {
  id: number
  brand: string
  icon_url: string
  urlName: string
}

const params: SwiperOptions = {
  slidesPerView: "auto",
}

type PartnersProps = {
  darkColor?: boolean
  orderNum?: string
  title?: string | ReactElement
  subTitle?: string | ReactElement
  variant?: "standart" | "newYear" | "cashBack100" | "autoPromo"
  additionalPartners?: Shop[]
  variantStyle?: "vkEducation"
}

export function Partners({
  darkColor,
  orderNum,
  title = "Магазины-партнеры",
  subTitle,
  variant = "standart",
  additionalPartners = partnersMain,
  variantStyle,
}: PartnersProps) {
  const [shops, setShops] = useState<Shop[]>([...additionalPartners])
  const otherVariantStyle = variantStyle && styles[variantStyle]

  const removeDuplicatesPartners = (localPartners: Shop[], backPartners: Shop[]): Shop[] => {
    const frontEndUrls = localPartners.map((partner) => partner.brand?.toLowerCase())
    const frontEndUrlsSet = new Set(frontEndUrls)
    return backPartners.filter((partner) => !frontEndUrlsSet.has(partner.urlName?.toLowerCase()))
  }

  function getShops(offset?: number) {
    fetchShops(offset).then((partners) => {
      const filteredShops = removeDuplicatesPartners(additionalPartners, partners)
      setShops((prev) => prev.concat(filteredShops))
    })
  }

  useEffect(() => {
    getShops()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onSlideNext(instance: SwiperCore) {
    const { realIndex, slides } = instance
    const partnersLen = slides.length

    if (realIndex >= partnersLen - 10) {
      getShops(partnersLen)
    }
  }

  return (
    <section
      className={clsx(styles.section, otherVariantStyle)}
      id="partners"
      data-exclude={orderNum}
    >
      <Container>
        <div
          className={clsx(styles.title_container, styles[variant], darkColor && styles.darkColor)}
        >
          <h2 className={clsx(styles.title, styles[variant])}>{title}</h2>
          {subTitle && (
            <div className={clsx(styles.subTitlePartners, styles[variant])}> {subTitle} </div>
          )}
        </div>
        <section className="content-visibility-auto">
          <div>
            <Container className={styles.p_0}>
              <Swiper {...params} onSlideNextTransitionEnd={onSlideNext}>
                {shops.map((data) => (
                  <SwiperSlide className={styles.slide} key={data.id}>
                    <Box className="text-center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={styles.logo}
                      >
                        <LazyLoad offset={100} once>
                          <img alt={data.brand} src={data.icon_url} />
                        </LazyLoad>
                      </Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Container>
          </div>
        </section>
      </Container>
    </section>
  )
}
