// extracted by mini-css-extract-plugin
export var autoPromo = "partners-module--autoPromo--ckB5e";
export var cashBack100 = "partners-module--cashBack100--Ey0dV";
export var darkColor = "partners-module--darkColor--uBh6C";
export var logo = "partners-module--logo--UG7Y3";
export var newYear = "partners-module--newYear--qYwR4";
export var p_0 = "partners-module--p_0--Wqrue";
export var section = "partners-module--section---r2K8";
export var slide = "partners-module--slide--QyU-L";
export var subTitlePartners = "partners-module--subTitlePartners--wSEnC";
export var sub_title = "partners-module--sub_title--Qe4bu";
export var text = "partners-module--text--yE5nr";
export var title = "partners-module--title--1uHSH";
export var title_container = "partners-module--title_container--sVYzB";
export var vkEducation = "partners-module--vkEducation--ElEhZ";